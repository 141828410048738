<template>
  <div class="wedding-video">
    <BannerOther :img_url="img_url" :chinese_name='chinese_name' :english_name="english_name"></BannerOther>
    <div class="col-main-box">
      <Sidder :tab_selectIndex='tab_selectIndex'></Sidder>
      <div class="col-r-box">
        <!-- 导航栏 -->
        <div class="crumbs">
          <div class="cru-logo">
            <img src="../../assets/images/home/daohang_logo.png" alt="">
          </div>
          <el-breadcrumb separator=">">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>婚礼摄像</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="head-search" :class="hide? 'hidediv':''">
          <div class="head-title">
            <img src="../../assets/images/sidder/hlsx_dangqi.png" alt="">
            <span>档期查询</span>
          </div>
          <!-- <div class="year-box">
            <el-date-picker v-model="year_value" type="year" value-format="yyyy" format="yyyy" popper-class='yearStyle'
              placeholder="选择年" @change="yearchange" :clearable='false' :picker-options="yearoptions">
            </el-date-picker>
          </div>
          <div class="month-box">
            <el-date-picker v-model="month_value" type="month" popper-class='monthStyle' value-format="yyyy-MM"
              format="MM" placeholder="选择月" @change="monthchange" :clearable='false' :picker-options="monthoptions">
            </el-date-picker>
          </div>
          <div class="day-box" style="border-right: none;">
            <el-date-picker v-model="day_value" type="date" popper-class='dateStyle' value-format="yyyy-MM-dd"
              format="dd" placeholder="选择日" @change="datechange" :clearable='false' :picker-options="dayoptions">
            </el-date-picker>
          </div> -->
          <!-- <div class="select-box">
						<el-select v-model="search_time" placeholder="请选择" @change="dangqichange">
							<el-option v-for="item in options" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div> -->
          <div class="all-time">
            <div class="re-data">
              <div class="day-box" style="border-right: none;">
                <el-date-picker v-model="day_value" type="date" popper-class='dateStyle' value-format="yyyy-MM-dd"
                  format="" placeholder="" @change="datechange" :clearable='false' :picker-options="dayoptions">
                </el-date-picker>
              </div>
            </div>
            <div class="year-box">
              <el-date-picker v-model="year_value" type="year" value-format="yyyy" format="yyyy" popper-class='yearStyle'
                placeholder="选择年" @change="yearchange" :clearable='false' :picker-options="yearoptions" disabled>
              </el-date-picker>
            </div>
            <div class="month-box">
              <el-date-picker v-model="month_value" type="month" popper-class='monthStyle' value-format="yyyy-MM"
                format="MM" placeholder="选择月" @change="monthchange" :clearable='false' :picker-options="monthoptions" disabled>
              </el-date-picker>
            </div>
            <div class="day-box" style="border-right: none;">
              <el-date-picker v-model="day_value" type="date" popper-class='dateStyle' value-format="yyyy-MM-dd"
                format="dd" placeholder="选择日" @change="datechange" :clearable='false' :picker-options="dayoptions" disabled>
              </el-date-picker>
            </div>
          </div>
          <div class="search-btn" @click="inquiry">查询</div>
        </div>
        <div class="head-search" :class="hide? 'hidediv':'head-search1'">
          <div class="head-title">
            <img src="../../assets/images/sidder/hlsx_dangqi.png" alt="">
            <span>档期查询</span>
          </div>
          <div class="second-box">
           <!-- <div class="year-box">
              <el-date-picker v-model="year_value" type="year" value-format="yyyy" format="yyyy"
                popper-class='yearStyle' placeholder="选择年" @change="yearchange" :clearable='false'
                :picker-options="yearoptions">
              </el-date-picker>
            </div>
            <div class="month-box">
              <el-date-picker v-model="month_value" type="month" popper-class='monthStyle' value-format="yyyy-MM"
                format="MM" placeholder="选择月" @change="monthchange" :clearable='false' :picker-options="monthoptions">
              </el-date-picker>
            </div>
            <div class="day-box" style="border-right: none;">
              <el-date-picker v-model="day_value" type="date" popper-class='dateStyle' value-format="yyyy-MM-dd"
                format="dd" placeholder="选择日" @change="datechange" :clearable='false' :picker-options="dayoptions">
              </el-date-picker>
            </div> -->
            <!-- <div class="select-box">
							<el-select v-model="search_time" placeholder="请选择" @change="dangqichange">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</div> -->
            <div class="all-time">
              <div class="re-data">
                <div class="day-box" style="border-right: none;">
                  <el-date-picker v-model="day_value" type="date" popper-class='dateStyle' value-format="yyyy-MM-dd"
                    format="" placeholder="" @change="datechange" :clearable='false' :picker-options="dayoptions">
                  </el-date-picker>
                </div>
              </div>
              <div class="year-box">
                <el-date-picker v-model="year_value" type="year" value-format="yyyy" format="yyyy" popper-class='yearStyle'
                  placeholder="选择年" @change="yearchange" :clearable='false' :picker-options="yearoptions" disabled>
                </el-date-picker>
              </div>
              <div class="month-box">
                <el-date-picker v-model="month_value" type="month" popper-class='monthStyle' value-format="yyyy-MM"
                  format="MM" placeholder="选择月" @change="monthchange" :clearable='false' :picker-options="monthoptions" disabled>
                </el-date-picker>
              </div>
              <div class="day-box" style="border-right: none;">
                <el-date-picker v-model="day_value" type="date" popper-class='dateStyle' value-format="yyyy-MM-dd"
                  format="dd" placeholder="选择日" @change="datechange" :clearable='false' :picker-options="dayoptions" disabled>
                </el-date-picker>
              </div>
            </div>
            <div class="search-btn" @click="inquiry">查询</div>
          </div>
        </div>
        <div class="relust" :class="hide? 'showdiv':''">搜索结果：</div>
        <!-- 有数据时 -->
        <div class="video-main-box" v-if="HaveData">
          <div class="video-list" v-for="(item,index) in DataList" :key="index">
            <div class="y-new-title" v-if="item.segment == '导演'&& item.arr.length > 0">
              <div class="video-logo">
                <img src="../../assets/images/sidder/sx_daoyan.png" alt="">
              </div>
              <div class="video-jw">可定机位：<span>{{item.grade}}个</span></div>
            </div>
            <div class="y-new-title" v-if="item.segment == '总监'&& item.arr.length > 0">
              <div class="video-logo">
                <img src="../../assets/images/sidder/sx_zongjian.png" alt="">
              </div>
              <div class="video-jw">可定机位：<span>{{item.grade}}个</span></div>
            </div>
            <div class="y-new-title" v-if="item.segment == '首席'&& item.arr.length > 0">
              <div class="video-logo">
                <img src="../../assets/images/sidder/sx_shouxi.png" alt="">
              </div>
              <div class="video-jw">可定机位：<span>{{item.grade}}个</span></div>
            </div>
            <div class="y-new-title" v-if="item.segment == '高级'&& item.arr.length > 0">
              <div class="video-logo">
                <img src="../../assets/images/sidder/sx_gaoji.png" alt="">
              </div>
              <div class="video-jw">可定机位：<span>{{item.grade}}个</span></div>
            </div>
            <div class="y-new-title" v-if="item.segment == '专业'&& item.arr.length > 0">
              <div class="video-logo">
                <img src="../../assets/images/sidder/sx_zhuanye.png" alt="">
              </div>
              <div class="video-jw">可定机位：<span>{{item.grade}}个</span></div>
            </div>
            <div class="y-new-title" v-if="item.segment == '基础'&& item.arr.length > 0">
              <div class="video-logo">
                <img src="../../assets/images/sidder/sx_jichu.png" alt="">
              </div>
              <div class="video-jw">可定机位：<span>{{item.grade}}个</span></div>
            </div>
            <div class="y-new-title" v-if="item.segment == '附加选项'&& item.arr.length > 0">
              <div class="video-logo">
                <img src="../../assets/images/sidder/sx_fujiaxuanxiang.png" alt="">
              </div>
              <div class="video-jw">可定机位：<span>{{item.grade}}个</span></div>
            </div>
            <div class="all-photographer">
              <div class="all-plg-item" v-for="(item1,index1) in item.arr" :key="index1.id"
                @click="changepage(item1.id,item1.name)">
                <div class="plg-item">
                  <!-- 	<div class="top-box" :class="item.segment == '导演' ? 'dy-color-backbg':
									item.segment == '总监' ? 'zj-color-backbg': item.segment == '首席' ? 'sx-color-backbg':
									item.segment == '高级' ? 'gj-color-backbg': item.segment == '专业' ? 'zy-color-backbg':
									item.segment == '基础' ? 'jc-color-backbg': item.segment == '附加选项' ? 'fjxx-color-backbg':''"></div>
									<div class="footer-box" :class="item.segment == '导演' ? 'dy-footer-backbg':
									item.segment == '总监' ? 'zj-footer-backbg': item.segment == '首席' ? 'sx-footer-backbg':
									item.segment == '高级' ? 'gj-footer-backbg': item.segment == '专业' ? 'zy-footer-backbg':
									item.segment == '基础' ? 'jc-footer-backbg': item.segment == '附加选项' ? 'fjxx-footer-backbg':''"></div> -->

                  <div class="top-box"></div>
                  <div class="footer-box"></div>

                  <img :src="item1.image" alt="">
                </div>
                <div class="coll-info">
                  <span class="info-name">{{item1.name}}</span>
                  <!-- 	<span class="info-price"
										:style="{'color':(item.segment == '导演' ? '#9203C7': item.segment == '总监' ? '#499EE7':item.segment == '首席' ? '#EAAE65':
										item.segment == '高级' ? '#897DD4': item.segment == '专业' ? '#A66047': item.segment == '基础' ? '#4A4D5E':
										item.segment == '附加选项' ? '#6a8199':'')}">￥{{item1.price}}</span> -->
                  <span class="info-price" style="color:#4A4D5E;">￥{{item1.price}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 无数据时 -->
        <div v-else class="nodata">
          <img src="../../assets/images/sidder/zanwuneirong.png" alt="">
          暂无数据~
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import BannerOther from '@/components/banner-other/index.vue'
  import Sidder from '@/components/sidder/index.vue'
  import {
    gethlshexiangList,
    getvideojiweilist
  } from '@/api/joggle.js'
  export default {
		name:'Wedding-Video',
    props: ['keyword'],
    components: {
      BannerOther,
      Sidder
    },
    data() {
      return {
        img_url: '', // 栏目背景图
        chinese_name: '婚礼摄像',
        english_name: 'WEDDING VIDEO',
        tab_selectIndex: 2,
        year_value: '',
        month_value: '',
        day_value: '',
        search_date: '', //搜索年月日
        search_time: '', // 搜索时间
        nowyear: '', //当前年份
        nowmonth: '', //当前月份
        nowday: '', //当前日期
        options: [{
          value: '1',
          label: '上午档'
        }, {
          value: '2',
          label: '下午档'
        }],
        DataList: [{
            segment: '导演',
            arr: []
          },
          {
            segment: '总监',
            arr: []
          },
          {
            segment: '首席',
            arr: []
          },
          {
            segment: '高级',
            arr: []
          },
          {
            segment: '专业',
            arr: []
          },
          {
            segment: '基础',
            arr: []
          },
          {
            segment: '附加选项',
            arr: []
          }
        ],
        top_keyword: '',
        yearoptions: {
          disabledDate(time) {
            return time.getTime() < new Date();
          }
        },
        monthoptions: {
          disabledDate(time) {
            return time.getTime() < new Date();
          }
        },
        dayoptions: {
          disabledDate(time) {
            var showyear = time.getFullYear();
            const nowyear = new Date().getFullYear();
            var showmonth = time.getMonth() + 1;
            const nowmonth = new Date().getMonth() + 1;
            if (showyear < nowyear) { // 过去的年份
              return true
            } else if (showyear == nowyear) { // 现在的年份
              if (showmonth < nowmonth) {
                return true
              } else if (showmonth == nowmonth) {
                var showday = time.getDate();
                const nowday = new Date().getDate();
                return showday < nowday
              } else {
                return false
              }
            } else {
              return false
            }
          }
        },
        hide: false,
        HaveData: false
      }
    },
    watch: {
      // 顶部搜索
      keyword(val, oldval) {
        var that = this
        that.top_keyword = val;
        if (that.top_keyword == '') {
          that.getList();
        } else {
          that.getList('姓名搜索');
        }
        that.getNumber();
      }
    },
    activated() {
      var that = this
      // 获取头图(栏目图)
      var company_info = JSON.parse(localStorage.getItem('company_info'));
      if (company_info) {
        that.img_url = company_info.shexiang_image;
      }
      // 判断是否有搜索项
      that.search_time = that.$route.query.search_time;
      that.search_date = that.$route.query.search_date;
      that.top_keyword = that.$route.query.top_keyword;
      if (!that.top_keyword) {
        that.top_keyword = ''
      }
      if (that.search_time || that.search_date) {
        var firstnum = that.search_date.indexOf('-');
        var lastnum = that.search_date.lastIndexOf('-');
        that.year_value = that.search_date.slice(0, firstnum);
        that.month_value = that.search_date.slice(0, lastnum);
        that.day_value = that.search_date;
      } else {
        var data = new Date();
        // 默认当前年份
        that.nowyear = data.getFullYear().toString();
        // 默认当前月份
        that.nowmonth = (data.getMonth() + 1).toString() < 10 ? '0' + (data.getMonth() + 1).toString() : (data
          .getMonth() + 1).toString();
        // 默认当前日期
        that.nowday = data.getDate().toString() < 10 ? '0' + data.getDate().toString() : data.getDate().toString();
        that.year_value = that.nowyear;
        that.month_value = that.year_value + '-' + that.nowmonth;
        that.day_value = that.month_value + '-' + that.nowday;
      }
      that.getList();
    },

    methods: {
      getNumber(DataList) { //获取可定机位数
        var that = this
        var obj = {
          date: that.day_value
        }
        getvideojiweilist(obj).then(res => {
          if (res.code == 1) {
            var data = res.data;
            that.DataList.forEach((item, index) => {
              if (item.segment == '导演') {
                item.grade = data.ajia_num;
              }
              if (item.segment == '总监') {
                item.grade = data.zongjian_num;
              }
              if (item.segment == '首席') {
                item.grade = data.shouxi_num;
              }
              if (item.segment == '高级') {
                item.grade = data.gaoji_num;
              }
              if (item.segment == '专业') {
                item.grade = data.zhuanye_num;
              }
              if (item.segment == '基础') {
                item.grade = data.jichu_num;
              }
              if (item.segment == '附加选项') {
                item.grade = data.fujia_num;
              }
            })
            console.log(that.DataList)
            // 数据为空时
            if (that.DataList[0].arr.length == 0 && that.DataList[1].arr.length == 0 && that.DataList[2].arr
              .length == 0&&that.DataList[3].arr.length == 0&&that.DataList[4].arr.length == 0&&that.DataList[5].arr.length == 0&&that.DataList[6].arr.length == 0) {
              that.HaveData = false;
            } else {
              that.HaveData = true;
            }
          }
        })
      },
      // 点击查询
      inquiry() {
        var that = this
        // 年月日处理  未进行日期选择时，查询当前时间
        if (that.search_date == '') {
          that.search_date = that.day_value;
        }
        that.top_keyword = '';
        that.getList();
        that.getNumber();
      },
      // 档期选择时
      dangqichange(e) {
        var that = this
        if (e) {
          that.search_time = e;
        }
      },
      // 年份变化时
      yearchange(e) {
        var that = this
        if (e) {
          that.year_value = e;
          that.month_value = that.year_value + '-' + that.nowmonth;
          that.day_value = that.month_value + '-' + that.nowday;
          that.search_date = that.day_value; // 搜索日期
        }
      },
      // 月份变化时
      monthchange(e) {
        var that = this
        if (e) {
          that.month_value = e;
          that.day_value = that.month_value + '-' + that.nowday;
          that.search_date = that.day_value; // 搜索日期
        }
      },
      // 日期变化时
      datechange(e) {
        var that = this
        if (e) {
          that.day_value = e;
          // 更新年份 月份
          var y_num = e.indexOf('-');
          that.year_value = e.slice(0, y_num);
          var m_num = e.lastIndexOf('-');
          that.month_value = e.slice(0, m_num);
          that.search_date = that.day_value; // 搜索日期
        }
      },
      // 获取婚礼摄像列表
      getList(name) {
        var that = this
        if (that.top_keyword != '') {
          name = '姓名搜索'
        } else {
          name = ''
        }
        var obj = {
          date: that.search_date,
          time: that.search_time,
          keyword: that.top_keyword
        }
        gethlshexiangList(obj).then(res => {
          if (res.code == 1) {
            if (name == '姓名搜索') {
              that.hide = true;
            } else {
              that.hide = false;
            }
            that.DataList.forEach((item, index) => {
              if (item.segment == '导演') {
                item.arr = res.data[7];
              }
              if (item.segment == '总监') {
                item.arr = res.data[8];
              }
              if (item.segment == '首席') {
                item.arr = res.data[9];
              }
              if (item.segment == '高级') {
                item.arr = res.data[10];
              }
              if (item.segment == '专业') {
                item.arr = res.data[11];
              }
              if (item.segment == '基础') {
                item.arr = res.data[12];
              }
              if (item.segment == '附加选项') {
                item.arr = res.data[13];
              }
            })
           that.getNumber();
          }
        })
      },
      changepage(id, name) {
        var that = this
        that.$router.push({
          path: '/video-details',
          query: {
            operate: '婚礼摄像',
            id: id,
            name: name,
            search_date: that.search_date,
            search_time: that.search_time,
            top_keyword: that.top_keyword
          }
        })
      },

    },
    beforeDestroy() {

    }
  }
</script>

<style lang="scss">
  @import '../../assets/css/wedding-video.scss';
  @import '../../assets/css/public.scss';
</style>
