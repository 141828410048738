<template>
	<!-- 相应的banner图（除首页之外） -->
	<div class="banner-other">
		<div class="back-img" ref="photoRef">
			<img :src="img_url" alt="">
		</div>
		<div class="mask">
			<div class="typy-name">
				<div>{{chinese_name}}</div>
				<div class="hui-color">{{english_name}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['img_url', 'chinese_name', 'english_name'],
		data() {
			return {

			}
		}
	}
</script>

<style lang="scss">
	.banner-other {
		font-family: '微软雅黑';
		position: relative;
		height: 341px;
		overflow: hidden;
		.mask {
			position: absolute;
			top: 0;
			height: 100%;
			width: 100%;
			background: rgba(0, 0, 0, 0.6);
			display: flex;
			align-items: center;
			box-sizing: border-box;
			padding-left:12.5%;
			
			.typy-name {
				font-size: 38px;
				transition: all 0.4s;
				font-weight: bold;
				color: #FFFFFF;
				margin-right: auto;
				div{
					transition: all 0.4s;
				}
				div:hover{
					transition: all 0.4s;
					transform: scale(1.1);
				}
				.hui-color {
					font-size: 28px;
					font-weight: bold;
					color: rgba(255, 255, 255, 0.08);
					line-height: 34px;
					margin-top: 25px;
					// max-width: 450px;
				}
			}

			img {
				width: 24px;
				height: 24px;
			}
		}

		.back-img {
			width: 100%;
			height: 100%;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
	@media screen and (max-width: 576px) { 
		.banner-other .mask .typy-name{ font-size: 24px;}
		.banner-other .mask .typy-name .hui-color{font-size: 20px; margin-top: 0;}
	}
	
	@media screen and (max-width: 993px){
		.banner-other .mask{padding-left: 6%;}
	}
	@media screen and (min-width: 994px) and (max-width:1200px){
		.banner-other .mask{padding-left: 7%;}
	}
</style>
